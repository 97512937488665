import { useState, useEffect, useContext } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { OpenCloseContactContext } from "../contexts/contact";
import Header from "../components/header";
import Footer from "../components/footer";
import { AppLoader } from "../components/app-loader";

export default function NewsSingle() {
  const [article, setArticle] = useState({});
  let { setOpenCloseContact } = useContext(OpenCloseContactContext);
  const singleArticle = useMatch(WEB_ROUTES.NEWS + "/:news");
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState({});
  const [featuredArticleImage, setFeaturedArticleImage] = useState("");

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    setLoading(true);

    //Get service
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/posts",
          singleArticle?.params?.news + "&_embed&order_by=menu_order&order=asc"
        )
      )
      .then((res) => {
        let serviceResponse = res.data?.length ? res.data[0] : {};
        setArticle(serviceResponse);

        /*let articleImage = serviceResponse?.content?.rendered?.match(
          /<img[^>]*src="([^"]+)"[^>]*>/
        );*/

        let articleImage = serviceResponse?.content?.rendered?.match(
          /^<figure(.*)><img[^>]*src="([^"]+)"[^>]*><\/figure>$/m
        );

        setFeaturedArticleImage(
          articleImage.length
            ? articleImage[0]?.match(/<img[^>]*src="([^"]+)"[^>]*>/)
            : ""
        );
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, [singleArticle]);

  return (
    <>
      <Header />
      <main className="bg-[#270a51]">
        {Object.keys(article).length && (
          <>
            <section className="bg-white relative">
              <div
                className={`container mx-auto bg-no-repeat bg-right-top md:bg-[length:75%_auto] bg-none ${
                  featuredArticleImage?.length ? "md:pt-80 pt-0" : ""
                }`}
                style={{
                  backgroundImage: `url(${
                    featuredArticleImage?.length && featuredArticleImage[1]
                      ? featuredArticleImage[1]
                      : ""
                  })`,
                }}
              >
                {featuredArticleImage?.length && featuredArticleImage[1] ? (
                  <img
                    src={featuredArticleImage[1]}
                    className={`m-auto object-cover md:hidden visible`}
                  />
                ) : null}

                <div className="mx-auto md:flex">
                  <div
                    className={` ${
                      featuredArticleImage?.length
                        ? "md:w-4/6 w-full"
                        : "w-full"
                    } md:px-16 md:py-10 pt-10 bg-[#47188e] text-white`}
                  >
                    <div className="relative md:px-0 px-8">
                      <span
                        className="font-medium text-[#ffa8bd]"
                        dangerouslySetInnerHTML={{
                          __html:
                            new Date(article.date).getDate() +
                            " " +
                            monthNames[new Date(article.date).getMonth()] +
                            " " +
                            new Date(article.date).getFullYear(),
                        }}
                      />
                      <h1 className="pt-5 text-4xl font-medium">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: article?.title?.rendered,
                          }}
                        />
                      </h1>
                    </div>
                  </div>
                  {featuredArticleImage?.length ? (
                    <div className="md:w-2/6 w-full md:p-10 p-0"></div>
                  ) : null}
                </div>
              </div>
            </section>

            <section>
              <div className="container mx-auto">
                <div className="mx-auto md:flex">
                  <div className="md:w-3/12 w-full md:p-16 p-8 bg-[#47188e] text-white">
                    Share this post
                  </div>
                  <article className="md:w-9/12 w-full py-16 md:px-16 px-8 bg-white">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: article?.content?.rendered
                          ?.replace(
                            featuredArticleImage?.length &&
                              featuredArticleImage[0]
                              ? featuredArticleImage[0]
                              : "",
                            ""
                          )
                          ?.replace(/<p>/g, "<p class='mb-5'>"),
                      }}
                    />
                  </article>
                </div>
              </div>
            </section>
          </>
        )}
      </main>
      <Footer />
    </>
  );
}
