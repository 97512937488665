import { useState, useEffect, useContext, Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { OpenCloseContactContext } from "../contexts/contact";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import FormContact from "./form-contact";

export default function Header() {
  const location = useLocation();

  return (
    <header className="bg-white">
      <nav
        className="container mx-auto flex items-center justify-between py-4 md:px-0 px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink to={WEB_ROUTES.HOME} className="inline-block w-48 h-auto">
            <span className="sr-only">Third Way Investment Partners Logo</span>
            <img
              src="/images/thirdway-logo.svg"
              alt="Third Way Investment Partners Logo"
            />
          </NavLink>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
          >
            <span className="sr-only">Open main menu</span>
            <svg
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          <NavLink to={WEB_ROUTES.HOME} className="leading-6 font-medium">
            Home
            <div className={``}></div>
            {WEB_ROUTES.HOME === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.ABOUT} className="leading-6 font-medium">
            About
            <div className={``}></div>
            {WEB_ROUTES.ABOUT === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.FUNDS} className="leading-6 font-medium">
            Funds
            <div className={``}></div>
            {WEB_ROUTES.FUNDS === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink
            to={WEB_ROUTES.INVESTMENTS}
            className="leading-6 font-medium"
          >
            Investments
            <div className={``}></div>
            {WEB_ROUTES.INVESTMENTS === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.IMPACT} className="leading-6 font-medium">
            Impact
            <div className={``}></div>
            {WEB_ROUTES.IMPACT === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.NEWS} className="leading-6 font-medium">
            News & Insights
            <div className={``}></div>
            {WEB_ROUTES.NEWS === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.CONTACT} className="leading-6 font-medium">
            Contact Us
            <div className={``}></div>
            {WEB_ROUTES.CONTACT === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>
        </div>
      </nav>
    </header>
  );
}
