import { NavLink } from "react-router-dom";

export default function NewsItem({ article }) {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return (
    <>
      <div className="flex flex-col items-start ">
        <div
          className="relative w-full md:pt-0 pt-60 bg-no-repeat bg-center bg-cover md:bg-none"
          style={{
            backgroundImage: `url(${article?._embedded["wp:featuredmedia"][0]?.source_url})`,
          }}
        >
          <NavLink
            className="overflow-hidden md:inline-block hidden h-full"
            to={`/news/` + article?.slug}
          >
            <img
              src={article?._embedded["wp:featuredmedia"][0]?.source_url}
              alt={article?.title?.rendered}
              className="aspect-[16/9] h-full bg-gray-100 object-cover sm:aspect-[2/1] lg:aspect-[3/2] transition duration-700 ease-in-out transform hover:scale-110"
            />
          </NavLink>

          <div className="absolute bottom-0 left-0 bg-[#47188e] p-2 text-xs">
            <span className="text-white">
              <span
                dangerouslySetInnerHTML={{
                  __html:
                    new Date(article.date).getDate() +
                    " " +
                    monthNames[new Date(article.date).getMonth()] +
                    " " +
                    new Date(article.date).getFullYear(),
                }}
              />
            </span>
          </div>
        </div>
        <div className="max-w-xl">
          <div className="relative">
            <h3 className="mt-6 text-2xl hover:text-[#47188e]">
              <NavLink to={`/news/` + article?.slug}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: article?.title?.rendered,
                  }}
                />
              </NavLink>
            </h3>

            <p
              className="mt-5 text-gray-600"
              dangerouslySetInnerHTML={{
                __html: article?.excerpt?.rendered,
              }}
            />

            <NavLink
              to={`/news/` + article?.slug}
              className="mt-5 float-left text-[#47188e]"
            >
              {" "}
              Read more <span aria-hidden="true">→</span>
            </NavLink>
          </div>
        </div>
      </div>
    </>
  );
}
