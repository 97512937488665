import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import { NavLink } from "react-router-dom";
import Header from "../components/header";
import Footer from "../components/footer";
import TeamItem from "../components/team-item";

export default function Team() {
  const [team, setTeam] = useState([]);
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [teamMedia, setTeamMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "team"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get team
        axios
          .get(
            CONTENT_ROUTES.TEAM("?order_by=menu_order&order=asc&per_page=100")
          )
          .then((res) => {
            setTeam(res.data);

            //Get team media
            let media = [];

            res.data?.map((member, i) => {
              if (member?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(member?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setTeamMedia((prevState) => [...prevState, media[i]]);
                  });
              return member?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="bg-white pt-16 pb-8">
          <div className="container mx-auto md:px-0 px-8">
            <div className="text-base leading-7 text-[#270a51]">
              <h1 className="pb-5 text-4xl font-semibold">
                {page?.title?.rendered}
              </h1>
              <div
                className="max-w-5xl"
                dangerouslySetInnerHTML={{
                  __html: page?.content?.rendered,
                }}
              />
            </div>
          </div>
        </section>

        <section className="bg-white pb-16">
          <div className="container mx-auto md:px-0 px-8">
            <div className="grid grid-cols-1 gap-x-0.5 gap-y-0.5 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-4 pl-0 list-none">
              {team.map((member, i) => (
                <Fragment key={i}>
                  <TeamItem
                    member={member}
                    memberMedia={teamMedia?.find(
                      (media) => media.id === member?.featured_media
                    )}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </section>
      </main>

      <Footer />
    </>
  );
}
