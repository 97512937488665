import { Fragment, useRef, useCallback, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import NewsItemLanding from "../components/news-item-landing";
import FormNewsletter from "../components/form-newsletter";
// import Swiper core and required modules
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
// import Swiper bundle with all modules installed
import { Swiper, SwiperSlide } from "swiper/react";
// import styles bundle
import "swiper/css/bundle";

export default function Home() {
  const [page, setPage] = useState({});
  const [childPages, setChildPages] = useState([]);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [banners, setBanners] = useState([]);
  const [bannerMedia, setBannerMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "home"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get child pages
        axios
          .get(
            CONTENT_ROUTES.POST_PAGE_BY_PARENT(
              "/pages",
              pageResponse?.id + "&_embed"
            )
          )
          .then((res) => {
            setChildPages(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);
    axios
      .get(
        CONTENT_ROUTES.POST_PAGE(
          "/posts",
          "&_embed&order_by=menu_order&order=desc&per_page=100"
        )
      )
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);
    //Get banners
    axios
      .get(CONTENT_ROUTES.BANNERS("?order_by=menu_order&order=asc"))
      .then((res) => {
        setBanners(res.data);

        //Get banner media
        let media = [];

        res.data?.map((banner, i) => {
          if (banner?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(banner?.featured_media))
              .then((res) => {
                media[i] = res.data;
                setBannerMedia((prevState) => [...prevState, media[i]]);
              });
          return banner?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const sliderRef = useRef(null);

  return (
    <>
      <Header />
      <main>
        <section className="flex justify-end bg-[#f7f7f5]">
          <div className="relative banner w-full">
            <Swiper
              ref={sliderRef}
              modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
              effect="gl"
              /* onBeforeInit={(swiper) => (swiper.params.gl.shader = "morph-x")} */
              slidesPerView={1}
              navigation
              direction={"horizontal"}
              loop={true}
              speed={1000}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
            >
              {banners?.map((banner, i) => (
                <SwiperSlide
                  key={i}
                  className="swiper-slide bg-[#f7f7f5] content-stretch"
                >
                  <div className="container mx-auto md:flex h-full items-center">
                    <div className="md:w-1/3 w-full md:pr-10 md:p-0 p-8 md:bg-none bg-[#f7f7f5] md:relative absolute z-50 bottom-0 left-0">
                      <h2
                        className="md:text-4xl text-2xl font-medium mb-4"
                        dangerouslySetInnerHTML={{
                          __html: banner?.title?.rendered,
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: banner?.content?.rendered?.replace(
                            /<p>/g,
                            "<p class='mb-4'>"
                          ),
                        }}
                      />

                      {banner?.acf?.banner_cta?.title && (
                        <NavLink
                          to={banner?.acf?.banner_cta?.url}
                          className="bg-[#270a51] hover:bg-[#47188e] px-7 py-4 text-white border border-white inline-block text-xl"
                        >
                          {banner?.acf?.banner_cta?.title}{" "}
                          <span aria-hidden="true">→</span>
                        </NavLink>
                      )}
                    </div>
                    <div className="md:w-2/3 w-full h-full relative">
                      <div
                        className="image"
                        style={{
                          backgroundImage: `url(${
                            bannerMedia?.find(
                              (media) => media.id === banner?.featured_media
                            )?.source_url
                          })`,
                        }}
                      ></div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            {/* <div onClick={handlePrev} className="swiper-button-prev p-2">
              <i className="fi fi-br-angle-left text-4xl text-[#47188e]"></i>
            </div>
            <div onClick={handleNext} className="swiper-button-next p-2">
              <i className="fi fi-br-angle-right text-4xl text-[#47188e]"></i>
            </div> */}
          </div>
        </section>

        <section
          className="relative bg-[#270a51] bg-no-repeat md:bg-left-top bg-right-bottom md:bg-[length:100%_auto] bg-none"
          style={{
            backgroundImage: `url(
              ${
                pageMedia?.find((media) => media?.id === page?.featured_media)
                  ?.source_url
              }
            )`,
          }}
        >
          <div className="container mx-auto md:flex">
            <div className="mx-auto md:w-1/2 flex-shrink-0 md:pl-0 md:pt-16 md:pr-16 px-8 py-16 text-white">
              <h1
                className="pb-8 text-4xl font-medium"
                dangerouslySetInnerHTML={{
                  __html: page?.content?.rendered
                    ?.replace(/<p.*?>*?<\/p>/g, "")
                    ?.replace(/<h2.*?>/g, ""),
                }}
              />
              <div
                className="mb-8 "
                dangerouslySetInnerHTML={{
                  __html: page?.content?.rendered
                    ?.replace(/<h2.*?>*?<\/h2>/g, "")
                    ?.replace(/<p>/g, "<p class='mb-4'>"),
                }}
              />

              <NavLink
                to={WEB_ROUTES.INVESTMENTS}
                className="bg-[#270a51] px-7 py-4 text-white hover:bg-white hover:text-[#270a51] border border-white inline-block text-xl"
              >
                Partner with us <span aria-hidden="true">→</span>
              </NavLink>
            </div>
            <div className="mx-auto flex md:w-1/2">
              {/* <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
                <img
                  src={`${
                    pageMedia?.find(
                      (media) => media?.id === page?.featured_media
                    )?.source_url
                  }`}
                  className="absolute  h-full w-1/2 object-cover z-0 top-0 right-0"
                />
              </div> */}
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="absolute w-full h-full bg-[#270a51] left-0 top-0 -z-20"></div>
          <div className="container mx-auto">
            <div className="md:w-full flex content-center py-16 md:px-16 px-8 bg-white ">
              {childPages?.map((child, i) => (
                <div key={i} className="md:flex">
                  {child.slug === "about-ip" && (
                    <>
                      <div className="md:w-2/5 pr-8 md:mb-0 mb-8">
                        <h2
                          className="md:pb-8 text-4xl font-medium text-[#270a51]"
                          dangerouslySetInnerHTML={{
                            __html: child?.title?.rendered,
                          }}
                        />
                        <NavLink
                          to={WEB_ROUTES.ABOUT}
                          className="text-[#47188e] text-xl md:inline-block hidden"
                        >
                          Lead the way <span aria-hidden="true">→</span>
                        </NavLink>
                      </div>
                      <div
                        className="md:w-3/5 "
                        dangerouslySetInnerHTML={{
                          __html: child?.content?.rendered.replace(
                            /<p>/g,
                            "<p class='mb-5'>"
                          ),
                        }}
                      />
                      <NavLink
                        to={WEB_ROUTES.ABOUT}
                        className="text-[#47188e] text-xl md:hidden inline-block"
                      >
                        Lead the way <span aria-hidden="true">→</span>
                      </NavLink>
                    </>
                  )}
                </div>
              ))}
            </div>
            <div className="md:w-full md:flex content-center justify-end">
              <div className="md:w-2/4 p-8 text-white md:flex hidden justify-end">
                <div className="md:content-end text-lg">
                  <p>Fulu Makwetla</p>
                  <p className="font-bold">Managing Director</p>
                </div>
              </div>
              <div className="md:w-2/4 overflow-hidden md:block hidden">
                <img
                  src="/images/Fulu-Makwetla.webp"
                  className="h-auto w-full"
                />
              </div>
            </div>
          </div>

          <div className="mx-auto max-w-7xl lg:flex lg:justify-between lg:px-8 xl:justify-end">
            <div className="lg:flex lg:w-1/2 lg:shrink lg:grow-0 xl:absolute xl:inset-y-0 xl:right-1/2 xl:w-1/2 -z-10">
              <div className="relative h-80 lg:-ml-8 lg:h-auto lg:w-full lg:grow xl:ml-0">
                <div className="absolute left-0 top-0 -z-10 w-full h-full bg-gradient-to-l from-black md:to-100% to-100% opacity-40 md:block hidden"></div>
                <img
                  className="md:absolute inset-0 h-full w-full bg-gray-50 top-16 object-cover -z-20"
                  src={`${
                    childPages?.filter((child) => child.slug === "about-ip")[0]
                      ?._embedded["wp:featuredmedia"][0]?.source_url
                  }`}
                />
              </div>
            </div>
          </div>
        </section>

        <section className="bg-[#270a51] md:py-16 pt-16 text-white">
          <div className="mx-auto container ">
            {childPages?.map((child, i) => (
              <div key={i}>
                {child.slug === "the-third-way-difference" && (
                  <>
                    <div className="max-w-3xl md:px-0 px-8">
                      <h2
                        className="text-4xl font-medium mb-4"
                        dangerouslySetInnerHTML={{
                          __html: child?.title?.rendered,
                        }}
                      />
                      <div
                        className=""
                        dangerouslySetInnerHTML={{
                          __html: child?.content?.rendered,
                        }}
                      />
                    </div>

                    <div className="mx-auto md:flex mt-8 md:gap-x-8 ">
                      <div className="bg-[#47188e] p-8 md:w-1/3">
                        <h3 className="font-medium mb-4 text-2xl md:text-white text-[#ffa8bd]">
                          {child?.acf?.card_1_title}
                        </h3>
                        <p>{child?.acf?.card_1_copy}</p>
                      </div>

                      <div className="bg-[#47188e] p-8 md:w-1/3">
                        <h3 className="font-medium mb-4 text-2xl md:text-white text-[#ffa8bd]">
                          {child?.acf?.card_2_title}
                        </h3>
                        <p>{child?.acf?.card_2_copy}</p>
                      </div>

                      <div className="bg-[#47188e] p-8 md:w-1/3 md:pb-0 pb-16">
                        <h3 className="font-medium mb-4 text-2xl md:text-white text-[#ffa8bd]">
                          {child?.acf?.card_3_title}
                        </h3>
                        <p>{child?.acf?.card_3_copy}</p>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        </section>

        <section
          className="relative md:p-20"
          /* style={{
            backgroundImage: `url(
              ${
                childPages?.filter(
                  (child) => child.slug === "supporting-south-africa"
                )[0]?._embedded["wp:featuredmedia"][0]?.source_url
              }
            )`,
          }} */
        >
          <video
            loop
            autoplay=""
            muted
            playsinline
            width="100%"
            className="md:absolute inset-0 -z-10 h-96 w-full object-cover"
          >
            <source src="/videos/GettyImages-1390823629.mp4" type="video/mp4" />
            This browser does not support HTML video.
          </video>
          <div className="mx-auto container">
            <div className="md:w-2/4 bg-white float-end md:text-right md:py-10 md:px-10 py-16 px-8">
              {childPages?.map((child, i) => (
                <div key={i}>
                  {child.slug === "supporting-south-africa" && (
                    <>
                      <h2
                        className="text-4xl font-medium mb-4 text-[#270a51]"
                        dangerouslySetInnerHTML={{
                          __html: child?.title?.rendered,
                        }}
                      />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: child?.content?.rendered.replace(
                            /<p>/g,
                            "<p class='mb-5'>"
                          ),
                        }}
                      />
                    </>
                  )}
                </div>
              ))}

              <NavLink
                to={WEB_ROUTES.IMPACT}
                className="text-[#47188e] text-xl md:inline-block"
              >
                Lead the way <span aria-hidden="true">→</span>
              </NavLink>
            </div>
            <div className="clear-both"></div>
          </div>
        </section>

        <section className="bg-white py-16 md:border-none border-t ">
          <div className="mx-auto container md:px-0 px-8">
            <div className="mx-full">
              <h2 className="text-4xl font-medium float-left text-[#270a51]">
                Latest news and insights
              </h2>

              <NavLink
                to={WEB_ROUTES.ABOUT}
                className="text-[#47188e] text-xl md:inline-block float-right hidden mt-0.5"
              >
                More Insights & News <span aria-hidden="true">→</span>
              </NavLink>
              <div className="clear-both"></div>
            </div>
            <div className="mx-auto md:mt-16 mt-5 grid max-w-2xl grid-cols-1 gap-x-8 md:gap-y-20 gap-y-10 lg:mx-0 lg:max-w-none lg:grid-cols-3">
              {posts?.slice(0, 3).map((article, i) => (
                <Fragment key={i}>
                  <NewsItemLanding article={article} />
                </Fragment>
              ))}
            </div>
            <div className="mx-full md:pt-0 pt-10">
              <NavLink
                to={WEB_ROUTES.ABOUT}
                className="text-[#47188e] text-xl inline-block md:float-right md:hidden"
              >
                More Insights & News <span aria-hidden="true">→</span>
              </NavLink>
              <div className="clear-both"></div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
