import { useState, useEffect } from "react";
import { useFormValidation } from "../hooks/form-validation";
import { useForms } from "../hooks/forms";
import Alert from "./alert";

export default function FormContact() {
  let { validFormDetails } = useFormValidation();
  let { submitContactForm } = useForms();
  const [invalidFormDetails, setInvalidFormDetails] = useState({});
  const [formDetails, setFormDetails] = useState({});
  const [formError, setFormError] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);

  const formFields = [
    { name: "first_name", display: "your First name" },
    { name: "last_name", display: "your Last name" },
    { name: "email", display: "your Email address" },
    { name: "message", display: "Message" },
  ];

  useEffect(() => {
    /* Defaults */
    setFormDetails((prevState) => ({
      ...prevState,
      [`first_name`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`last_name`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`email`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`organisation`]: "",
    }));
    setFormDetails((prevState) => ({
      ...prevState,
      [`message`]: "",
    }));

    return () => {
      setInvalidFormDetails({});
      setFormDetails({});
      setFormError("");
      setFormSubmitted(false);
    };
  }, []);

  return (
    <>
      {!formSubmitted ? (
        <form
          onSubmit={(event) => {
            event.preventDefault();
            submitContactForm(
              formFields,
              formDetails,
              setInvalidFormDetails,
              setFormError,
              setFormSubmitted
            );
          }}
          method="POST"
          className="mx-full"
        >
          <div className="grid grid-cols-1 gap-x-4 gap-y-1 sm:grid-cols-2">
            <div>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  placeholder="First name"
                  autoComplete="first_name"
                  className={`block w-full border-0 px-3.5 py-2 bg-[#47188e] placeholder-white placeholder-opacity-30 focus-visible:outline-none ${
                    invalidFormDetails.first_name
                      ? `bg-[#8c75b7]`
                      : `bg-[#47188e]`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`first_name`]: event.target.value,
                    }));
                  }}
                  value={formDetails.first_name ? formDetails.first_name : ""}
                />
              </div>
            </div>
            <div>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  placeholder="Last name"
                  autoComplete="last_name"
                  className={`block w-full border-0 px-3.5 py-2 bg-[#47188e] placeholder-white placeholder-opacity-30 focus-visible:outline-none ${
                    invalidFormDetails.last_name
                      ? `bg-[#8c75b7]`
                      : `bg-[#47188e]`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`last_name`]: event.target.value,
                    }));
                  }}
                  value={formDetails.last_name ? formDetails.last_name : ""}
                />
              </div>
            </div>

            <div>
              <div className="mt-2.5">
                <input
                  type="text"
                  name="organisation"
                  id="organisation"
                  placeholder="Organisation"
                  autoComplete="organisation"
                  className={`block w-full border-0 px-3.5 py-2 bg-[#47188e] placeholder-white placeholder-opacity-30 focus-visible:outline-none ${
                    invalidFormDetails.organisation
                      ? `bg-[#8c75b7]`
                      : `bg-[#47188e]`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`organisation`]: event.target.value,
                    }));
                  }}
                  value={
                    formDetails.organisation ? formDetails.organisation : ""
                  }
                />
              </div>
            </div>
            <div>
              <div className="mt-2.5">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Email address"
                  autoComplete="email"
                  className={`block w-full border-0 px-3.5 py-2 bg-[#47188e] placeholder-white placeholder-opacity-30 focus-visible:outline-none ${
                    invalidFormDetails.email ? `bg-[#8c75b7]` : `bg-[#47188e]`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`email`]: event.target.value,
                    }));
                  }}
                  value={formDetails.email ? formDetails.email : ""}
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <div className="mt-2.5">
                <textarea
                  name="message"
                  id="message"
                  rows="4"
                  placeholder="Message"
                  autoComplete="message"
                  className={`block w-full border-0 px-3.5 py-2 bg-[#47188e] placeholder-white placeholder-opacity-30 focus-visible:outline-none ${
                    invalidFormDetails.message ? `bg-[#8c75b7]` : `bg-[#47188e]`
                  }`}
                  onFocus={() => {
                    validFormDetails(
                      formFields,
                      formDetails,
                      setInvalidFormDetails,
                      setFormError
                    );
                  }}
                  onChange={(event) => {
                    setFormDetails((prevState) => ({
                      ...prevState,
                      [`message`]: event.target.value,
                    }));
                  }}
                  value={formDetails.message ? formDetails.message : ""}
                ></textarea>
              </div>
            </div>
            <div className="md:flex gap-x-6 sm:col-span-2 items-start mt-5">
              <p className="text-sm">
                This form is protected by reCAPTCHA. Google's{" "}
                <a
                  href="https://policies.google.com/privacy?hl=en-GB"
                  target="_blank"
                  className="underline text-white"
                >
                  Privacy Policy
                </a>
                {` `}
                and
                {` `}
                <a
                  href="https://policies.google.com/terms?hl=en-GB"
                  target="_blank"
                  className="underline text-white"
                >
                  Terms of Service
                </a>
                {` `}
                apply.
              </p>

              <button
                type="submit"
                className=" bg-[#e86861] px-5 py-2 text-white inline-block ml-auto md:mt-0 mt-4"
              >
                Lead the way <span aria-hidden="true">→</span>
              </button>
            </div>
            <div className="sm:col-span-2">
              {formError.length !== 0 && (
                <div className="py-4 text-[#e86861]">{formError}</div>
              )}
            </div>
          </div>
        </form>
      ) : (
        <>
          <Alert
            type={`success`}
            message={`<b>Successfully submitted.</b> We will be in touch with you shortly.`}
          />
        </>
      )}
    </>
  );
}
