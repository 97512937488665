export const WEB_ROUTES = {
  HOME: `/`,
  ABOUT: `/about`,
  TEAM: `/team`,
  FUNDS: `/funds`,
  INVESTMENTS: `/investments`,
  IMPACT: `/impact`,
  NEWS: `/news`,
  TEAM: `/team`,
  CAREERS: `/careers`,
  CONTACT: `/contact-us`,
  NEWSLETTER: `/subscribe-to-our-mailing-list`,
  LEGAL: `/legal`,
  TERMS: `/legal/terms-of-use`,
  PRIVACY_POLICY: `/legal/privacy-policy`,
  CONFLICT_POLICY: `/legal/conflict-of-interest-policy/`,
  COOKIE_POLICY: `/legal/cookie-policy`,
  COPYRIGHT_STATEMENT: `/legal/copyright-statement`,
  NOT_FOUND: `/404`,
};
