import { useState } from "react";
import { WEB_ROUTES } from "../routes/web-routes";
import { NavLink } from "react-router-dom";

export default function TeamItem({ member, memberMedia, type = "main" }) {
  return (
    <>
      <div className="group relative transition-all duration-300 ease-in-out text-center mb-10">
        <NavLink
          to={WEB_ROUTES.TEAM + "/" + member?.slug}
          className={`${
            type === "embed" && "w-60 h-60 rounded-full"
          } overflow-hidden inline-block`}
        >
          <img
            src={memberMedia?.source_url}
            alt={member?.title?.rendered}
            className={`${
              type === "embed" && "w-60 h-60 rounded-full"
            } m-auto object-cover transition duration-700 ease-in-out transform group-hover:scale-110`}
          />
        </NavLink>

        <div className="w-full">
          <h4 className="mt-2 font-medium text-[#47188e]">
            <NavLink to={WEB_ROUTES.TEAM + "/" + member?.slug}>
              {member?.title?.rendered}{" "}
            </NavLink>
          </h4>
          <p className="mb-4 text-base">
            {member?.acf?.team_member_designation}
          </p>
          <NavLink
            to={WEB_ROUTES.TEAM + "/" + member?.slug}
            className="relative inline-block h-[30px] w-[30px]"
          >
            <span aria-hidden="true" className="text-[#47188e]">
              →
            </span>
          </NavLink>
        </div>
      </div>
    </>
  );
}
