import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { AppLoader } from "../components/app-loader";
import { WEB_ROUTES } from "../routes/web-routes";
import TeamItem from "../components/team-item";

export default function Investments() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsMedia, setProjectsMedia] = useState([]);

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "investments"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    //Get projects
    axios
      .get(
        CONTENT_ROUTES.PROJECTS("?order_by=menu_order&order=asc&per_page=100")
      )
      .then((res) => {
        setProjects(res.data);

        //Get projects media
        let media = [];

        res.data?.map((project, i) => {
          if (project?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(project?.featured_media))
              .then((res) => {
                media[i] = res.data;
                setProjectsMedia((prevState) => [...prevState, media[i]]);
              });
          return project?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Header />
      <main>
        <section className="bg-white">
          <div className="container mx-auto">
            <div className="md:flex">
              <div className="md:w-2/6 w-full md:py-60 py-8 md:px-16 px-8">
                <h1 className="py-5 text-4xl font-medium text-[#270a51]">
                  {page?.title?.rendered}
                </h1>
              </div>
              <div
                className="md:w-4/6 w-full bg-white md:pt-20 pt-96 bg-no-repeat bg-center bg-cover"
                style={{
                  backgroundImage: `url(${
                    pageMedia?.find(
                      (media) => media?.id === page?.featured_media
                    )?.source_url
                  })`,
                }}
              ></div>
            </div>
          </div>
        </section>

        <section className="bg-[#270a51] md:py-16 pt-16">
          <div className="container mx-auto md:px-16 px-8">
            <div
              className="text-white max-w-6xl"
              dangerouslySetInnerHTML={{
                __html: page?.content?.rendered,
              }}
            />
          </div>

          <div className="container mx-auto md:py-16 pt-16 md:space-y-20">
            {projects?.map((project, i) => (
              <div key={i} className="md:flex">
                <div
                  className="md:w-1/2 w-full bg-white md:pt-20 pt-80 bg-no-repeat bg-center bg-cover"
                  style={{
                    backgroundImage: `url(${
                      projectsMedia?.find(
                        (media) => media?.id === project?.featured_media
                      )?.source_url
                    })`,
                  }}
                ></div>
                <div
                  className={`${
                    i % 2 === 0 ? "bg-white" : "bg-[#47188e] text-white"
                  } md:w-1/2 w-full pt-8 pb-16 md:py-16 md:px-16 px-8`}
                >
                  <h3
                    className={`${
                      i % 2 === 0 ? "text-[#270a51]" : "text-white"
                    }`}
                    dangerouslySetInnerHTML={{
                      __html: project?.title?.rendered,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: project?.content?.rendered?.replace(
                        /<p>/g,
                        "<p class='mb-4'>"
                      ),
                    }}
                  />
                </div>
              </div>
            ))}
          </div>

          <div className="container mx-auto bg-[#47188e] p-16 md:px-16 px-8 text-white">
            <h2
              className="font-medium mb-4 text-4xl"
              dangerouslySetInnerHTML={{
                __html: page?.acf?.cta_title,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: page?.acf?.cta_content?.replace(
                  /<p>/g,
                  "<p class='mb-4'>"
                ),
              }}
            />
            <NavLink
              to={page?.acf?.cta_button.url}
              className="flex-none bg-[#e86861] px-5 py-2 text-white inline-block mt-10"
            >
              {page?.acf?.cta_button.title} <span aria-hidden="true">→</span>
            </NavLink>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
